import React, {useEffect, useState} from 'react';
import {Dimensions, TouchableOpacity} from 'react-native';
import { Radio, Image, Select, HStack, VStack, Pressable, Box, Circle, Text, Center, Icon, Button} from 'native-base';
import { useSpring, animated } from '@react-spring/web';
import {translate} from '../helpers';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import store from '../store';
import cartsActions from '../store/carts/actions';
import Storage from '../storage/Storage';

const AnimatedBox = animated(Box);
const AnimatedVStack = animated(VStack);
const AnimatedHStack = animated(HStack);
const AnimatedImage = animated(Image);

export default function MenuItem({
    menu,
    originalPrice,
    itemInCartQty,
    onConfirm,
}) {
    const [expand, setExpand] = useState(false);
    const [price, setPrice] = useState(menu.price);
    const [quantity, setQuantity] = useState(0);
    const [extras, setExtras] = useState([]);

    const dimensions = Dimensions.get('window');
    const isMobile = Window.innerWidth <= 768;
    const deviceWidth = isMobile ? window.innerWidth : 445;
    const fullImageW = Math.round(deviceWidth * 0.9);

    const placeholderImg = require('../assets/placeholder.jpg');

    const [{ width, borderBottomRadius, opacity1, opacity2, displaySmallDesc, displayLongDesc }, setSelectedProps] = useSpring(() => ({
        displaySmallDesc: 'flex',
        width: 150,
        borderBottomRadius: 10,
        opacity1: 1,
        opacity2: 0,
        displayLongDesc: 'none',
    }));

    const onExpand = (value) => {
        if (value) {
            setSelectedProps({
                displaySmallDesc: 'flex',
                width: fullImageW,
                borderBottomRadius: 0,
                opacity1: 0,
                opacity2: 1,
                displayLongDesc: 'flex',
                config: { duration: 200 },
            });
        } else {
            setSelectedProps({
                displaySmallDesc: 'flex',
                width: 150,
                borderBottomRadius: 10,
                opacity1: 1,
                opacity2: 0,
                displayLongDesc: 'none',
                config: { duration: 100 },
            });
        }
        setExpand(value);
    }

    /**
     * Re calculate price when there are changes on
     * Quantity or Extras
     */
    useEffect(() => {
        //console.log('Extra is updated', extras);
        let qty = quantity === 0 ? 1 : quantity;
        let price = parseFloat(menu.price);
        let addOns = 0;
        for (const extra of extras) {
            for (const item of extra.items) {
                if (extra.type === 'upgrade') {
                    price += parseFloat(item.menu_price);
                } else {
                    addOns += parseFloat(item.menu_price);
                }
            }
        }
        price = (price * qty) + addOns;
        setPrice(price);
    }, [quantity, extras]);

    const reset = () => {
        setQuantity(0);
        setExtras([]);
        setPrice(menu.price);
    }

    const onCancelled = () => {
        onExpand(false);
        reset();
    }

    function makeId(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result;
    }

    const onAddedToOrder = () => {
        if(quantity > 0){
            //onExpand(false);
            setSelectedProps({
                displaySmallDesc: 'flex',
                width: 150,
                borderBottomRadius: 10,
                opacity1: 1,
                opacity2: 0,
                displayLongDesc: 'none',
                config: { duration: 80 },
                onRest: () => {
                    let items = {
                        id: makeId(8),
                        menu_item_id: menu.id,
                        menu_name: menu.name,
                        qty:  quantity,
                        price: menu.price,
                        //variation,
                        extra: extras,
                        createdAt: new Date(),
                        onFinished: () => {}
                    }
                    //onConfirm(items);
                    store.dispatch(cartsActions.addToCart(items));
                    reset();
                }
            });
        }
    }

    const onAddExtra = (value) => {
        if (value === null || value.length === 0) {
            return;
        }

        let qty = quantity;
        if (quantity === 0) {
            qty = qty + 1;
            setQuantity(qty);
        }

        const option = value.split('|');
        const optionId = option[0];

        if (option.includes('none')) {
            let newExtras = extras.filter(item => {
                return item.option_id !== optionId;
            });
            return setExtras(newExtras);
        }

        const type = option[1];
        const menuItemId = option[2];
        const menuName = option[3];
        const menuPrice = option[4];

        let newExtras = extras.filter(item => {
            return item.option_id !== optionId;
        })

        newExtras.push({
            option_id: optionId,
            type,

            //for create order payload
            menu_item_ids: [menuItemId],

            //for display purposes
            items: [
                {
                    menu_item_id: menuItemId,
                    menu_name: menuName,
                    menu_price: menuPrice,
                }
            ]
        });

        setExtras(newExtras);
    }

    const onSetVariation = (value) => {
        if (value === 'remove') {
            return setVariation(null);
        }
        setVariation(value);
    }

    const onPlus= async ()=> {
        const qty = quantity + 1;
        setQuantity(qty);
    }

    const onMinus = async ()=> {
        const qty = quantity - 1 < 0 ? 0 : quantity - 1;
        setQuantity(qty);
    }

    return(
        <Box width="95%" mb={8}>
            <Pressable onPress={() => onExpand(!expand)} style={{cursor: 'pointer'}}>
                <AnimatedHStack bg="#322923"
                    style={{
                        borderRadius: 10,
                        borderBottomRightRadius: borderBottomRadius,
                        borderBottomLeftRadius: borderBottomRadius
                    }}
                >   
                    {/* <AnimatedImage
                        source={require('../assets/placeholder.jpg')}
                        height={150}
                        width={150}
                        style={{
                            //width,
                            position: 'absolute',
                            borderRadius: 10,
                            // borderBottomRightRadius: borderBottomRadius,
                            // borderBottomLeftRadius: borderBottomRadius
                        }}
                    /> */}
                    <animated.img
                        src={menu.photo ? menu.photo : require('../assets/placeholder.jpg')}
                        height={150}
                        style={{
                            width,
                            position: 'absolute',
                            borderRadius: 10,
                            borderBottomRightRadius: borderBottomRadius,
                            borderBottomLeftRadius: borderBottomRadius,
                            cursor: 'pointer',
                            objectFit: 'cover'
                        }}
                    />
                    <AnimatedHStack space={2} position="absolute" bottom={0} left={4} mb={2}>
                        <Circle size="23px" bg='#707070'>
                            <Text color="#fff" fontSize="sm">GF</Text>
                        </Circle>
                        <Circle size="23px" bg='#707070'>
                            <Text color="#fff" fontSize="sm">DF</Text>
                        </Circle>
                    </AnimatedHStack>

                    {itemInCartQty > 0 && (
                        <Circle size="23px" position="absolute" top={-4} left={-4} style={{backgroundColor: '#df2351'}}>
                            <Text color="#fff" fontSize="sm">{itemInCartQty}</Text>
                        </Circle>
                    )}

                    <AnimatedBox height={150} flex={1} px={4} style={{
                        display: displaySmallDesc,
                        opacity: opacity1,
                        marginLeft: 150,
                        paddingVertical: 10,
                    }}>
                        <Box height="80%">
                            <Text lineHeight="xs" color="#eae5da" fontSize="xl">{menu.name}</Text>
                            <Text lineHeight="xs" mt={2} isTruncated numberOfLines={2} color="#707070" fontSize="sm" fontWeight="light">
                                {menu.description}
                            </Text>
                        </Box>
                        <Box height="20%" justifyContent="end">
                            <Text color="#eae5da" fontSize="lg" fontWeight="bold">
                                ${parseFloat(price).toFixed(2)}{' '}
                                {typeof originalPrice !== 'undefined' && (
                                    <Text strikeThrough color="#aaa" fontSize="sm" fontWeight="normal">${originalPrice}</Text>
                                )}
                            </Text>
                        </Box>
                    </AnimatedBox>
                </AnimatedHStack>
            </Pressable>

            <AnimatedVStack style={{display: displayLongDesc, opacity: opacity2}} roundedBottom="lg" bg="#fffbf2">
                <Box p={4}>
                    <Box>
                        <Text color="#5a3b28" fontSize="xl" fontWeight="bold">{menu.name}</Text>
                        <Text color="#707070" fontSize="sm" fontWeight="light">
                            {menu.description}
                        </Text>
                    </Box>

                    {menu.options && menu.options.length > 0 && (
                        <Box mt={4}>
                            {menu.options.map((option, index) => {
                                let selectedValue = `${option.id}|none`;
                                //console.log("item selected from menu", selectedValue);
                                
                                let filter = extras.filter(item => {
                                    return parseInt(item.option_id) === parseInt(option.id)
                                })
                                if (filter.length > 0) {
                                    let item = null;

                                    //if (option.selection === 'single') { //nov 29 comment for now since we dont have multiple selectoin
                                        item = filter[0]['items'][0];
                                    //}

                                    //console.log("value of item", item);

                                    selectedValue = `${option.id}|${option.type}|${item.menu_item_id}|${item.menu_name}|${item.menu_price}`;
                                }

                                //console.log('Selected value = ', extras);
                                //console.log('Selected value = ', selectedValue);
                                return (
                                    <Select
                                        mb={2}
                                        key={index}
                                        backgroundColor='#fff'
                                        fontSize="sm"
                                        placeholder='Select'
                                        selectedValue={selectedValue}
                                        onValueChange={(value) => onAddExtra(value)}>
                                        <Select.Item
                                            placeholder={
                                                selectedValue !== `${option.id}|none`
                                                    ? translate('Remove Selection') 
                                                    : option.name
                                            }
                                            value={`${option.id}|none`}
                                            label={
                                                selectedValue !== `${option.id}|none`
                                                    ? translate('Remove Selected') 
                                                    : option.name
                                            }
                                        />
                                        {option.items.map((item, index) => {
                                            return (
                                                <Select.Item
                                                    placeholder={option.name}
                                                    key={index}
                                                    value={`${option.id}|${option.type}|${item.menu_id}|${item.title}|${item.price}`}
                                                    label={`${item.title} - $${item.price}`}
                                                />
                                            );
                                        })}
                                    </Select>
                                );
                            })}
                        </Box>
                    )}

                    <HStack alignItems="center" justifyContent="space-between" my={2}>
                        <Box>
                            <Text color="#5a3b28" fontSize="xl" fontWeight="bold">
                                ${parseFloat(price).toFixed(2)}
                            </Text>
                        </Box>
                        <HStack space={2}>
                            {quantity > 0 && (
                                <Pressable onPress={onMinus} style={{cursor: 'pointer'}}>
                                    <Circle size="30px" bg='#707070'>
                                        <MaterialCommunityIcons color="#fff" name="minus" size={20} />
                                    </Circle>
                                </Pressable>
                            )}
                            <Box><Text color="#5a3b28" fontSize="xl" fontWeight="bold">{quantity > 0 ? quantity : ''}</Text></Box>
                            <Pressable onPress={onPlus} style={{cursor: 'pointer'}}>
                                <Circle size="30px" bg='#707070'>
                                    <MaterialCommunityIcons color="#fff" name="plus" size={20} />
                                </Circle>
                            </Pressable>
                        </HStack>
                    </HStack>
                    <HStack space={2} justifyContent="center">
                        <Button
                            onPress={onAddedToOrder}
                            width={100}
                            bg={quantity > 0 ? "#5a3b28" : "#aaa"}
                            disabled={quantity > 0 ? false : true}
                            style={{height: 35, borderRadius: 17.5,  cursor: 'pointer'}}
                        >
                            <Text color="#fff" fontSize="sm" fontWeight="bold">{translate('Add To Cart')}</Text>
                        </Button>
                        <Button onPress={onCancelled} width={100} bg="#eae5da" style={{height: 35, borderRadius: 17.5, cursor: 'pointer',}}>
                            <Text color="#707070" fontSize="sm" fontWeight="bold">{translate('Cancel')}</Text>
                        </Button>
                    </HStack>
                </Box>
            </AnimatedVStack>

        </Box>
    );
};