import initialState from './states';
import constants from '../constants';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.MENU_SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case constants.MENU_SET_MENUS:
            return {
                ...state,
                list: action.payload,
            }
        default:
            return state;
    }
};

