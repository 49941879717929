import React, {useEffect, useState} from 'react';
import { StyleSheet, TextInput, Alert } from 'react-native';
import { useNavigate } from "react-router-dom";
import { Stack,Box, HStack, Text, Heading, Image, Center, Icon, Button} from 'native-base';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import '../../styles/styles.css';
import MainContainer from '../layouts/Container';
import {translate} from '../helpers';
import { connect, useDispatch } from 'react-redux';
import ordersActions from '../store/orders/actions';
import paymentsActions from '../store/payments/actions';
import validator from 'validator';

const ORDERTYPE = {
    dine: 'dine_in',
    pickup: 'pickup',
    takeaway: 'takeaway',
    delivery: 'delivery',
}

function OrderConfirmation(props) {
    let dispatch = useDispatch();
    let navigate = useNavigate();

    MaterialCommunityIcons.loadFont();

    const [number, onChangeNumber] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [orderType, setOrderType] = React.useState(ORDERTYPE.dine);

    const goHome = () => {
        navigate("/");
    };

    useEffect(() => {
        if(props.carts.details.length <= 0 && props.orders.details){
            navigate("/thankyou");
        }
    }, [props.carts.details]);

   const onPlaceOrder =  async () => {
        switch(orderType) {
            case ORDERTYPE.dine:
            case ORDERTYPE.takeaway:

                Alert("went here");
                
                if(number === '' && orderType === ORDERTYPE.dine) {
                    alert("Please enter a table number.");
                    break; 
                }

                if(orderType === ORDERTYPE.takeaway && mobile.length > 0 && !validator.isMobilePhone(mobile)) {
                    alert('Please enter a valid mobile number.');
                    break;
                }

                if(props.carts.details.length > 0){
                    let payload = {
                        "order_type" : orderType,
                        "table_num" : number === '' ? 0 : number,
                        items: props.carts.details,
                        onFinished: () => {
                            navigate("/thankyou"); //redirect to thank you screen and set as paybytill for now march 2723-cha
                            //goDefault();
                        }
                    };

                    if(mobile.length > 0) {
                        payload.mobile = mobile;
                    }
                    
                    dispatch(ordersActions.sendOrderDetails(payload));
                 }
                    
                break;
            case ORDERTYPE.pickup:
                break;
                break;
            case ORDERTYPE.delivery:
                break;
        }
   }

    const onSetOrderType = value => {
        setOrderType(value);
    }

    const handleChange = (value) => {
        if (/[^0-9]/.test(value)) { 
            alert('Input numeric values only.');
        }else{
            onChangeNumber(value);
        }
    }

    const handleMobileChange = (value) => {
        setMobile(value);
    }

    return(
         <MainContainer
            title='Back'
            isHome={false}
            hideLogo = {false}
            hideMenu={true}
            imagePath={require('../assets/header.jpg')}>
              <Center>
                 <Box bg="#FFFFFF" padding={4} height='100%' width='98%' borderRadius={10}>
                      <Box mb={4} height="5%">
                            <Heading style={styles.content} fontWeight="bold"> {translate('Order Type')} </Heading>
                      </Box>
                      <Box>
                          <HStack justifyContent="space-evenly">
                              <Stack>
                                  <Button  onPress={() => onSetOrderType(ORDERTYPE.dine)}   height={50} width={50} top={30} bg="transparent" colorScheme="transparent">
                                       <Image src={orderType === ORDERTYPE.dine ? require('../assets/orderconfirmation/dine-in_on.png') : require('../assets/orderconfirmation/dine-in_off.png')} alt="dine"  height={50} width={50} />
                                  </Button>
                                  <Text mt={35} style={styles.title} >{translate('Dine-In')}</Text>
                              </Stack>
                              <Stack>
                                  <Button  onPress={() => onSetOrderType(ORDERTYPE.takeaway)}    height={50} width={50}  top={30} bg="transparent" colorScheme="transparent">
                                       <Image  src={orderType === ORDERTYPE.takeaway ? require('../assets/orderconfirmation/pick-up_on.png') : require('../assets/orderconfirmation/pick-up_off.png')} alt="takeaway"   height={50} width={50} />
                                  </Button>
                                  <Text mt={35}  ml={-2} style={styles.title}>{translate('TakeAway')}</Text>
                              </Stack>
                              <Stack>
                                  <Button  onPress={() => onSetOrderType(ORDERTYPE.pickup)}  disabled={true}  height={50} width={50}  top={30} bg="transparent" colorScheme="#808080">
                                       <Image  src={orderType === ORDERTYPE.pickup ? require('../assets/orderconfirmation/pick-up_on.png') : require('../assets/orderconfirmation/pickup_disabled.png')} alt="pickup"   height={50} width={50} />
                                  </Button>
                                  <Text mt={35}  ml={-2} style={styles.title}>{translate('Pickup')}</Text>
                              </Stack>
                              <Stack>
                                  <Button  onPress={() => onSetOrderType(ORDERTYPE.delivery)} disabled={true} height={50} width={50} top={30} bg="transparent" colorScheme="#808080">
                                       <Image src={orderType === ORDERTYPE.delivery ? require('../assets/orderconfirmation/delivery_on.png') : require('../assets/orderconfirmation/delivery_disabled.png')} alt="delivery"   height={50} width={50}  />
                                  </Button>
                                  <Text mt={35}  style={styles.title}>{translate('Delivery')}</Text>
                              </Stack>
                          </HStack>
                      </Box>

                     {orderType == ORDERTYPE.dine && (
                        <Box paddingTop={10} justifyContent="space-evenly">
                            <Heading style={styles.content} fontWeight="bold"> {translate('Table Number')} </Heading> 
                            <TextInput
                                style={styles.input}
                                onChangeText={handleChange}
                                value={number}
                                placeholder="Enter table number"
                                keyboardType="numeric"
                                maxLength={3}
                            />
                        </Box>
                     )}

                     {(orderType == ORDERTYPE.pickup || orderType == ORDERTYPE.takeaway) && (
                        <Box paddingTop={10} justifyContent="space-evenly">
                            <Heading style={styles.text}>{translate('Enter your mobile number if you want to be notified that your order is ready to pickup. Your mobile number will be deleted from the system after the text message is sent successfully.')} </Heading> 
                            <TextInput
                                style={styles.input}
                                onChangeText={handleMobileChange}
                                value={mobile}
                                placeholder="Enter mobile number"
                                keyboardType="phone-pad"
                            />
                        </Box>
                     )}

                      <Box>
                        <HStack paddingTop={10} alignItems="center" justifyContent="space-evenly" my={2}>
                            <Button disabled={props.orders.loading} borderRadius="20" height="8" width="50%" top={0} bg={props.orders.loading ? "#808080" : "#4d3728"} onPress={onPlaceOrder} colorScheme="transparent">
                                <Text style={styles.order}>{props.orders.loading ? translate('Sending...') : translate('Place Order')}</Text>
                            </Button>
                            <Button borderRadius="20" height="8" width="50%" top={0} bg="light.100" onPress={goHome} colorScheme="transparent">
                                <Text  style={styles.title}>{translate('Cancel')}</Text>
                            </Button>
                        </HStack>
                    </Box>
                  </Box>
              </Center>
        </MainContainer>
    );
}

const styles = StyleSheet.create({
    menuContainer: {
        marginBottom: 100
    },
    footer: {
        backgroundColor: '#2b3036',
        width: '100%',
        fontStyle: "Helvetica-NeueLT",
        fontSize:10,
        fontWeight:"normal",
        color:"#f5f5f4"
    },
    gridContainer:{
        backgroundColor: '#46362a',
        width: '100%',
    },
    heading:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:25,
        fontWeight:"normal",
        color:"#f5f5f4",
        width:230
    },
    title:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:15,
        fontWeight:"normal",
        color:"#4d3728",
        mt:17,
    },
    mainContainer:{
        height: 156,
    },
    content: {
        fontStyle: "Helvetica-NeueLT",
        fontSize:25,
        fontWeight:"normal",
        color:"#4d3728",
        width:165,
    },
    text: {
        fontStyle: "Helvetica-NeueLT",
        fontSize:14,
        fontWeight:"normal",
        color:"#4d3728",
        textAlign: 'left',
        marginHorizontal: 12,
    },
    tableNumber:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:25,
        fontWeight:"normal",
        color:"#4d3728",
        width:195,
        position:"absolute",
        top:-35,
        right:0,
    },
    order: {
        color: '#ffffff',
        fontStyle: "Helvetica-NeueLT",
        fontSize:15,
        fontWeight:"normal",
    },
    input: {
        height: 40,
        margin: 12,
        borderWidth: 1,
        padding: 10,
    },
    img:{
     paddingLeft:10,
    },
    cursor: 'pointer',
});

function mapStateToProps(state) {
    return {
        orders: state.orders,
        carts: state.carts,
        payments: state.payments,
    }
  }
  
export default connect(mapStateToProps)(OrderConfirmation);