import React, {useEffect,useState} from 'react';
import {Dimensions} from 'react-native';
import { Select, HStack, VStack, Pressable, Box, Circle, Text, Image, Center, Icon, Button} from 'native-base';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useSpring, animated } from '@react-spring/web';

const AnimatedBox = animated(Box);
const AnimatedVStack = animated(VStack);
const AnimatedHStack = animated(HStack);
const AnimatedImage = animated(Image);
MaterialCommunityIcons.loadFont();

export default function OrderItem({index, menu, onPress, onPlus, onMinus, onDeleteItem}) {
    const dimensions = Dimensions.get('window');
    const fullImageW = Math.round(dimensions.width * 0.9);

    const [{ width, borderBottomRadius, opacity1, opacity2, displaySmallDesc, displayLongDesc }, setSelectedProps] = useSpring(() => ({
        displaySmallDesc: 'flex',
        width: 150,
        borderBottomRadius: 10,
        opacity1: 1,
        opacity2: 0,
        displayLongDesc: 'none',
    }));

    let upgradedTo = '';
    let addOnsList = [];
    if (typeof menu.extra !== 'undefined' && menu.extra.length > 0) {
        const upgradeFilter = menu.extra.filter(item => {
            return item.type === 'upgrade';
        });
        const addOnFilter = menu.extra.filter(item => {
            return item.type === 'add-on' || item.type === 'variation';
        });
        if(upgradeFilter.length > 0) {
            const item = upgradeFilter[0]['items'][0];
            upgradedTo = item.menu_name;
        }
        if(addOnFilter.length > 0) {
            for (const addOn of addOnFilter) {
                for (const item of addOn.items) {
                    addOnsList.push(item.menu_name);   
                }
            }
        }
    }
    console.log('Add ons list = ', addOnsList);

    let price = parseFloat(menu.price);
    let addOns = 0;
    for (const extra of menu.extra) {
        for (const item of extra.items) {
            if (extra.type === 'upgrade') {
                price += parseFloat(item.menu_price);
            } else {
                addOns += parseFloat(item.menu_price);
            }
        }
    }
    price = (price * menu.qty) + addOns;

    return(
        <Box width="95%" mb={4}>
            <Pressable >
                <AnimatedHStack
                    style={{
                        borderRadius: 10,
                        borderBottomRightRadius: borderBottomRadius,
                        borderBottomLeftRadius: borderBottomRadius,
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                    }}
                >
                        <Box style={{width: '60%'}}>
                            <Text key={menu.menu_item_id} color="#322923" fontSize="sm" fontWeight="bold" numberOfLines={0}>
                                {upgradedTo !== '' ? `Upgraded to ${upgradedTo}` : menu.menu_name}
                            </Text>
                            {upgradedTo !== '' && (
                                <Text style={{ flexShrink: 1 }} strikeThrough color="#aaa" fontSize="xs">{menu.menu_name}</Text>
                            )}
                            {typeof menu.variation !== 'undefined' && menu.variation !== null && (
                                <Box><Text color="#aaa" fontSize="xs">{menu.variation}</Text></Box>
                            )}
                            {addOnsList.map((addon, i) => {
                                return (<Box key={i}><Text color="#aaa" fontSize="xs">{addon}</Text></Box>);
                            })}
                            <HStack space={2}>
                                <Pressable onPress={onMinus} style={{cursor: 'pointer'}}>
                                    <Circle size="30px" bg='#fffbf2'>
                                        <MaterialCommunityIcons color="#322923" name="minus" size={20} />
                                    </Circle>
                                </Pressable>
                                <Box><Text color="#322923" fontSize="md" fontWeight="bold">{menu.qty}</Text></Box>
                                <Pressable onPress={onPlus} style={{cursor: 'pointer'}}>
                                    <Circle size="30px" bg='#fffbf2'>
                                        <MaterialCommunityIcons color="#322923" name="plus" size={20} />
                                    </Circle>
                                </Pressable>
                            </HStack>
                        </Box>
                        <HStack alignItems="flex-start" justifyContent="space-between" my={2}>
                            <Box><Text color="#5a3b28" fontSize="md" fontWeight="bold">${parseFloat(price).toFixed(2)}</Text></Box>
                            <Pressable onPress={onDeleteItem} style={{cursor: 'pointer'}}>
                                <Circle size="30px" bg='#fffbf2'>
                                    <MaterialCommunityIcons color="#322923" name="delete" size={20} />
                                </Circle>
                            </Pressable>
                        </HStack>
                </AnimatedHStack>
            </Pressable>
        </Box>
    );
};