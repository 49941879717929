import React, {useEffect, useState} from 'react';
import { StyleSheet, View } from 'react-native';
import { useNavigate } from "react-router-dom";
import { Stack, ZStack, Text, Heading, Image, Center, Button} from 'native-base';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Carousel from 'react-bootstrap/Carousel';
import Grid from '@mui/material/Grid';
import MainContainer from '../layouts/Container';
import {translate} from '../helpers';
import { connect, useDispatch, useSelector } from 'react-redux';
import restoActions from '../store/restaurant/actions';
import '../../styles/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Skeleton from 'react-loading-skeleton';
import placeholderImg from '../assets/placeholder.jpg';

function Home(props) {
    MaterialCommunityIcons.loadFont();

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const loading = useSelector(state => state.categories.loading);
    const categories = useSelector(state => state.categories.list);

    const hostname = window.location.hostname;

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        
        let payload = {domain: hostname};
        if (params.b) payload = {...payload, branch_alias: params.b};

        dispatch(restoActions.getRestaurantDetails(payload));
    }, [hostname]);

    const goMenuItem = (category) => {
        navigate(`/menuItem/${category.id}`, {state: {category}});
    };

    const width = window.innerWidth <= 768 ? window.innerWidth: 400;
    const margin = 20;
    const imageSize = (width / 2) - (margin * 2);

    return (
        <MainContainer hideLogo={false} hideBack={true}>
            <Center>
                <View style={styles.specialsContainer} >
                    {loading && categories.length === 0 ? (
                        <Carousel interval={5000} controls={false} indicators={true} >
                            <Carousel.Item>
                                <Skeleton borderRadius={15} style={{width: width - (margin * 2) + 10, height: '70%'}} />
                            </Carousel.Item>
                        </Carousel>
                    ) : (
                        <Carousel interval={5000} controls={false} indicators={true} >
                            {['Specials','Brunch','Dinner','Beverages'].map(res => {
                                return (
                                    <Carousel.Item key={res}>
                                        <img src={require('../assets/homepage/Menu_' + res + '.png')} alt="logo"  />
                                    </Carousel.Item>
                                )
                            })}
                        </Carousel>
                    )}
                </View>
            </Center>

            <Stack space={3} p={9.5} style={{marginLeft: margin}}>
                <Heading style={styles.heading} color="light.100">{translate('Menu')}</Heading>
            </Stack>

            <Center>
                <Grid container>
                    {loading && categories.length === 0 ? (
                        <Grid container item spacing={3}>
                            {[...Array(6).keys()].map((key, index) => {
                                return (
                                    <Grid item xs={6} key={key}>
                                        <Grid item>
                                            <Button colorScheme="transparent">
                                                <Skeleton style={{
                                                    height: imageSize,
                                                    width: imageSize,
                                                    marginLeft: index % 2 ? margin / 2 : margin,
                                                    marginRight: index % 2 ? margin : margin / 2,
                                                    marginTop: 0,
                                                    marginBottom: 0
                                                }}/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    ) : (
                        <Grid container item spacing={3}>
                            {categories.map((category, index) => {
                                if(category.tag === 'options') return;

                                const photo = category.photo ? category.photo : placeholderImg;

                                return (
                                    <Grid item xs={6} key={category.id}>
                                        <Grid item key={category.id}>
                                            <Button value={category.id} onPress={() => goMenuItem(category)} colorScheme="transparent">
                                                <img
                                                    src={photo ? photo : placeholderImg}
                                                    style={{
                                                        marginLeft: index % 2 ? margin / 2 : margin,
                                                        marginRight: index % 2 ? margin : margin / 2,
                                                        marginTop: 0,
                                                        marginBottom: 0,
                                                        justifyContent: 'space-between',
                                                        width: imageSize,
                                                        height: imageSize,
                                                        borderRadius: 10,
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            </Button>
                                            <Center>
                                                <Text style={styles.title}>{translate(category.name)}</Text>
                                            </Center>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    )}
                </Grid>
            </Center>
        </MainContainer>
    );
}

const styles = StyleSheet.create({
    menuContainer: {
        marginBottom: 100
        
    },
    specialsContainer: {
        marginBottom: 1,
    },
    footer: {
        backgroundColor: '#2b3036',
        width: '100%',
        fontStyle: "Helvetica-NeueLT",
        fontSize:10,
        fontWeight:"normal",
        color:"#f5f5f4"
    },
    gridContainer:{
        backgroundColor: '#46362a',
        width: '100%',
    },
    heading:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:25,
        fontWeight:"normal",
        color:"#f5f5f4"
    },
    title:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:15,
        fontWeight:"normal",
        color:"#f5f5f4"
    },
    mainContainer:{
        height: 156,
        padding:0,
        margin:0,
    },
});

function mapStateToProps(state) {
    return {
        restaurant: state.restaurant,
        //categories: state.categories,
    }
  }
  
export default connect(mapStateToProps)(Home);