import React, { useEffect, useState } from 'react';
import { StyleSheet, ScrollView, Switch } from 'react-native';
import { useNavigate } from "react-router-dom";
import apiClient from '../webservice/Client';
import storage from '../storage/Storage';
import { Radio, Center, NativeBaseProvider, Text, HStack, Button, Box, Heading } from "native-base";
import '../../styles/styles.css';
import MainContainer from '../layouts/Container';
import { translate } from '../helpers';
import { connect, useDispatch } from 'react-redux';
import paymentsActions from '../store/payments/actions';

function PaymentMethod(props) {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const [value, setValue] = useState("till");

    const goPay = async () => {
        if(value == 'till'){
            dispatch(paymentsActions.onPayByTill({
                "order_id": props.orders.details.id,
                "status": "pay_in_till",
                onFinished: () => navigate("/thankyou")
            }));
        }else  if(value == 'card'){
            navigate("/checkout");
        }else{
            //setup google pay/applepay
            navigate("/checkout");
        }
    };

    const onCancel = () => {
        navigate("/thankyou")
    };

    return (
        <MainContainer
            title='Back'
            isHome={false}
            hideMenu={true}
            hideLogo={false}
            imagePath={null}>
            <Center>
                <Box bg="#FFFFFF" padding={4} height='100%' width='98%' borderRadius={10}>
                    <Box mb={10} height="5%">
                        <Heading style={styles.content} fontWeight="bold"> {translate('Payment Method')} </Heading>
                    </Box>
                    <Radio.Group defaultValue="card" name="myRadioGroup" accessibilityLabel="Pay by"
                        value={value} onChange={nextValue => {
                            setValue(nextValue);
                        }}>
                        <Radio value="till" colorScheme="brown" size="sm" my={1}>
                            <Text style={styles.title}>Pay by till</Text>
                        </Radio>
                        <Radio value="card" colorScheme="white" size="sm" my={1} isDisabled>
                            <Text style={[styles.title, {color: 'gray'}]}>Pay by Card</Text>
                        </Radio>
                        <Radio value="pay" colorScheme="white" size="sm" my={1} isDisabled>
                            <Text style={[styles.title, {color: 'gray'}]}>Pay by Applepay/Googlepay</Text>
                        </Radio>
                    </Radio.Group>
                    <HStack paddingTop={10} alignItems="center" justifyContent="space-evenly" my={2}>
                        <Button borderRadius="20" height="8" width="50%" top={0} bg="#4d3728" onPress={goPay} colorScheme="transparent">
                            <Text style={styles.order} >{props.payments.loading ? translate('Loading...') : translate('Pay')}</Text>
                        </Button>
                        <Button borderRadius="20" height="8" width="50%" top={0} bg="light.100" onPress={onCancel} colorScheme="transparent">
                            <Text style={styles.title}>{translate('Later')}</Text>
                        </Button>
                    </HStack>
                </Box>
            </Center>
        </MainContainer>
    )
}

const styles = StyleSheet.create({
    menuContainer: {
        marginBottom: 100
    },
    footer: {
        backgroundColor: '#2b3036',
        width: '100%',
        fontStyle: "Helvetica-NeueLT",
        fontSize: 10,
        fontWeight: "normal",
        color: "#f5f5f4"
    },
    gridContainer: {
        backgroundColor: '#46362a',
        width: '100%',
    },
    heading: {
        fontStyle: "Helvetica-NeueLT",
        fontSize: 25,
        fontWeight: "normal",
        color: "#f5f5f4",
        width: 230
    },
    title: {
        fontStyle: "Helvetica-NeueLT",
        fontSize: 15,
        fontWeight: "normal",
        color: "#4d3728",
        mt: 17,
    },
    mainContainer: {
        height: 156,
    },
    content: {
        fontStyle: "Helvetica-NeueLT",
        fontSize: 25,
        fontWeight: "normal",
        color: "#4d3728",
        width: '100%',
    },
    tableNumber: {
        fontStyle: "Helvetica-NeueLT",
        fontSize: 25,
        fontWeight: "normal",
        color: "#4d3728",
        width: 195,
        position: "absolute",
        top: -35,
        right: 0,
    },
    order: {
        color: '#ffffff',
        fontStyle: "Helvetica-NeueLT",
        fontSize: 15,
        fontWeight: "normal",
    },
    input: {
        height: 40,
        margin: 12,
        borderWidth: 1,
        padding: 10,
    },
    img: {
        paddingLeft: 10,
    },
    cursor: 'pointer',
});

function mapStateToProps(state) {
    return {
        orders: state.orders,
        payments: state.payments
    }
  }
  
export default connect(mapStateToProps)(PaymentMethod);