import React, { useEffect, useState } from 'react';
import { StyleSheet, ScrollView, Switch } from 'react-native';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Card, Stack, Box, Pressable, Select, ZStack, HStack, Text, Heading, Image, Center, Icon, Button } from 'native-base';

import '../../styles/styles.css';
import MainContainer from '../layouts/Container';
import { translate } from '../helpers';
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { style } from '@mui/system';


export default function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();
    

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    let navigate = useNavigate();

    const location = useLocation();

    console.log('hash', location.hash);
    console.log('pathname', location.pathname);
    console.log('search', location.search);

    console.log('current URL 👉️', window.location.origin);


    // useEffect(() => {
    //     if (!stripe) {
    //         return;
    //     }

    //     console.log('Stripe =', stripe);

    //     async function updatePayment() {
    //         const clientSecret = await storage.getSecretId();
    //         console.log("updatePayment client secret", clientSecret);

    //         if (!clientSecret) {
    //             return;
    //         }

    //         stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
    //             console.log('paymentIntent =', paymentIntent);
    //             switch (paymentIntent.status) {
    //                 case "succeeded":
    //                     console.log("Payment succeeded!");
    //                     break;
    //                 case "processing":
    //                     console.log("Your payment is processing.");
    //                     break;
    //                 case "requires_payment_method":
    //                     console.log("Your payment was not successful, please try again.");
    //                     break;
    //                 default:
    //                     console.log("Something went wrong.");
    //                     break;
    //             }
    //         });
    //     }
    //     updatePayment();

    // }, [stripe]);

    const handleSubmit = async (e) => {
        console.log('handleSubmit');
        e.preventDefault();

        console.log('handleSubmit stripe', stripe);
        console.log('handleSubmit elements', elements);

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                //return_url:  window.location.origin + '/thankYou',
                payment_method_data: {
                    billing_details: {
                      address:{
                        country:'PH',
                        postal_code:'6015',
                        state:'',
                        city:'',
                        line2:'',
                        line1:''

                      },
                    },
                },
            },
            redirect: 'if_required'
        });

        console.log('Error payment = ', error);

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error && (error.type === "card_error" || error.type === "validation_error")) {
            setMessage(error.message);
        }

        navigate('/thankYou');

        setIsLoading(false);
    };

    const paymentElementOptions = {
        type: 'accordion',
        defaultCollapsed: false,
        radios: true,
        spacedAccordionItems: false,
        paymentMethodOrder: ['apple_pay', 'google_pay', 'cards', 'klarna'],
        variables: {
            colorPrimary: '#322923',
            colorBackground: '#322923',
            colorText: '#ffffff',
            colorDanger: '#ffffff',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '2px',
            borderRadius: '4px',
            colorPrimaryText:'#ffffff'
            // See all possible variables below
          },
        fields: {
            billingDetails: {
              email:'auto',
              address: 'never'
            }
          },
        wallets:{
            applePay:'auto',
            googlePay:'auto',
        }

      }
    

    return (
        <MainContainer
            title='Checkout'
            isHome={false}
            hideMenu={true}
            imagePath={require('../assets/header.jpg')}>
            <Center>
                <form id="payment-form" onSubmit={handleSubmit} styles={style.formContainer}>
                    <PaymentElement id="payment-element" options={paymentElementOptions} />
                    <Stack>
                        <button style={{backgroundColor: '#4d3728', border: 0, marginTop: 10}} disabled={isLoading || !stripe || !elements} id="submit">
                            {isLoading 
                                ? <Text style={styles.order} >{translate('Loading...')}</Text>
                                : <Text style={styles.order} >{translate('Pay Now')}</Text>
                            }
                        </button>
                    </Stack>
                    {message && <div id="payment-message" style={{color:'#ffffff'}}>{message}</div>}
                </form>
            </Center>
        </MainContainer>
    );
}

const styles = StyleSheet.create({
    menuContainer: {
        marginBottom: 100
    },
    formContainer:{
       paddingTop:0,
       top:-10,
       borderColor:'red',

    },
    footer: {
        backgroundColor: '#2b3036',
        width: '100%',
        fontStyle: "Helvetica-NeueLT",
        fontSize: 10,
        fontWeight: "normal",
        color: "#f5f5f4"
    },

    heading: {
        fontStyle: "Helvetica-NeueLT",
        fontSize: 25,
        fontWeight: "normal",
        color: "#f5f5f4",
        width: 230
    },
    title: {
        fontStyle: "Helvetica-NeueLT",
        fontSize: 15,
        fontWeight: "normal",
        color: "#4d3728",
        mt: 10,
        ml: 7,
    },
    mainContainer: {
        backgroundColor: '#46362a',
        height: 500,
    },
    contentHeader: {
        fontStyle: "Helvetica-NeueLT",
        fontSize: 30,
        fontWeight: "normal",
        color: "#f5f5f4",
        size: "md",
    },
    content: {
        fontStyle: "Helvetica-NeueLT",
        fontSize: 20,
        fontWeight: "normal",
        width: 275,
        color: "#f5f5f4",
        left:-20,
    },
    order: {
        color: '#ffffff',
        fontStyle: "Helvetica-NeueLT",
        fontSize: 20,
        fontWeight: "normal",
        size: "md",
        textAlign: 'center'
    },
    cursor: 'pointer',

});