import constants from "../constants";
import { put, takeEvery, all, call } from 'redux-saga/effects';
import api from '../../webservice/Client';
import Storage from "../../storage/Storage";

function* getPaymentDetails({payload}) {
    try {
        let params = {...payload};
        yield put({ type: constants.PAYMENT_SET_LOADING, payload: true });
        const paymentDetails = yield call(api.getClientSecret, payload);

        yield call(Storage.setPaymentIntentId, paymentDetails.id);
        yield call(Storage.setSecretId, paymentDetails.client_secret);

        yield put({ type: constants.PAYMENT_SET_DETAILS, payload: paymentDetails });
        yield put({ type: constants.PAYMENT_SET_LOADING, payload: false });

        if(typeof payload.onFinished !== 'undefined') {
            payload.onFinished();
        }
    } catch(e) {
        yield put({ type: constants.ORDER_SET_LOADING, payload: false });
        if(typeof payload.onError !== 'undefined') {
            payload.onError();
        }
    }
}

function* payByTill({payload}) {
    try {
        yield put({ type: constants.PAYMENT_SET_LOADING, payload: true });
        yield call(api.updateOrderDemo, payload);
        yield put({ type: constants.PAYMENT_SET_LOADING, payload: false });

        if(typeof payload.onFinished !== 'undefined') {
            payload.onFinished();
        }
    } catch(e) {
        yield put({ type: constants.ORDER_SET_LOADING, payload: false });
        if(typeof payload.onError !== 'undefined') {
            payload.onError();
        }
    }
}

function* paymentSuccess({payload}) {
    try {
        console.log('Payment Success payload', payload);
        yield put({ type: constants.PAYMENT_SET_LOADING, payload: true });
        yield call(api.updatePaymentStatus, payload);
        yield put({ type: constants.PAYMENT_SET_LOADING, payload: false });

        if(typeof payload.onFinished !== 'undefined') {
            payload.onFinished();
        }
    } catch(e) {
        yield put({ type: constants.ORDER_SET_LOADING, payload: false });
        if(typeof payload.onError !== 'undefined') {
            payload.onError();
        }
    }
}

export default function* () {
    yield all([
        takeEvery(constants.PAYMENT_GET_DETAILS, getPaymentDetails),
        takeEvery(constants.PAYMENT_PAY_BY_TILL, payByTill),
        takeEvery(constants.PAYMENT_SUCCESS, paymentSuccess),
    ]);
}
