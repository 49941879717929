import React, {useEffect, useState} from 'react';
import { StyleSheet } from 'react-native';
import { useNavigate } from "react-router-dom";
import { Stack, Text, Heading, Center, Button} from 'native-base';

import '../../styles/styles.css';
import MainContainer from '../layouts/Container';
import {translate} from '../helpers';
import { connect, useDispatch } from 'react-redux';
import paymentsActions from '../store/payments/actions';

function ThankYou(props) {
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const goHome = () => {
        navigate("/");
    };

     useEffect( () => {
        if(props.orders.details && props.orders.details.status === 'paid') {
            let payload = {
                "order_id": props.orders.details.id,
                "payment_id": props.payments.details.id,
                "gateway": "stripe",
            };
            dispatch(paymentsActions.onPaymentSuccess(payload));
        }
     }, [props.orders.details, props.payments.details]);

    return(
         <MainContainer
            title='Success!'
            isHome={true}
            hideMenu={false}
            hideBack={true}
            imagePath={require('../assets/header.jpg')}>
              <Center>
                <Stack>
                    <Center><Heading style={styles.contentHeader}> {translate('Thanks for your order!')} </Heading></Center>
                </Stack>
                <Stack mt={15} >
                    <Heading style={styles.order}> {translate('Your order number is:')} </Heading>
                    <Heading style={styles.order}> {props.orders.details.queue}</Heading>
                </Stack>
                <Stack mt={20}>
                    <Heading style={styles.order}> {translate('Your order should arrive in')}</Heading>
                    <Heading style={styles.order}> {`${props.orders.details.total_preparation_time} mins.`}</Heading>
                    <Heading style={styles.order}> {translate('Please pay at the till.')}</Heading>
                </Stack>
                 <Stack mt={45}>
                     <Button borderRadius="20" height="8" width="185" top={0} bg="light.100" onPress={goHome} colorScheme="transparent">
                        <Text style={styles.title}>{translate('Menu')}</Text>
                     </Button>
                 </Stack>
            </Center>
        </MainContainer>
    );
}

const styles = StyleSheet.create({
    menuContainer: {
        marginBottom: 100
    },
    footer: {
        backgroundColor: '#2b3036',
        width: '100%',
        fontStyle: "Helvetica-NeueLT",
        fontSize:10,
        fontWeight:"normal",
        color:"#f5f5f4"
    },

    heading:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:25,
        fontWeight:"normal",
        color:"#f5f5f4",
        width:230
    },
    title:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:15,
        fontWeight:"normal",
        color:"#4d3728",
        mt:10,
        ml:7,
    },
    mainContainer:{
        backgroundColor: '#46362a',
        height: 156,
    },
    contentHeader: {
        fontStyle: "Helvetica-NeueLT",
        fontSize:30,
        fontWeight:"normal",
        color:"#f5f5f4",
        size:"md",
    },
    content: {
        fontStyle: "Helvetica-NeueLT",
        fontSize:20,
        fontWeight:"normal",
        width:275,
        color:"#f5f5f4",
    },
    order: {
        color: '#ffffff',
        fontStyle: "Helvetica-NeueLT",
        fontSize:20,
        fontWeight:"normal",
        size:"md",
        textAlign: 'center'
    },
    cursor: 'pointer',
});

function mapStateToProps(state) {
    return {
        orders: state.orders,
        payments: state.payments
    }
  }
  
export default connect(mapStateToProps)(ThankYou);