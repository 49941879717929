import constants from "../constants";
import { put, takeEvery, all, call } from 'redux-saga/effects';
import api from '../../webservice/Client';

function* getRestaurantDetails({payload}) {
    try {
        yield put({ type: constants.RESTAURANT_SET_LOADING, payload: true });
        yield put({ type: constants.CATEG_SET_LOADING, payload: true });
        const details = yield call(api.getRestoDetails, payload);
        yield put({ type: constants.RESTAURANT_SET_LOADING, payload: false });
        yield put({ type: constants.RESTAURANT_SET_DETAILS, payload: details });

        const categories = yield call(api.getCategories);
        yield put({ type: constants.CATEG_SET_CATEGORIES, payload: categories });
        yield put({ type: constants.CATEG_SET_LOADING, payload: false });
    } catch(e) {
        yield put({ type: constants.RESTAURANT_SET_LOADING, payload: false });
        yield put({ type: constants.CATEG_SET_LOADING, payload: false });
    }
}

export default function* () {
    yield all([
        takeEvery(constants.RESTAURANT_GET_DETAILS, getRestaurantDetails),
    ]);
}
