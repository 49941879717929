import constants from "../constants";
import { put, takeEvery, all, call } from 'redux-saga/effects';
import api from '../../webservice/Client';

function* getMenuList({payload}) {
    try {
        //
    } catch(e) {
        //
    }
}

export default function* () {
    yield all([
        takeEvery(constants.MENU_GET_MENUS, getMenuList),
    ]);
}
