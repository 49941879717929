import initialState from './states';
import constants from '../constants';
import storage from '../../storage/Storage';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.RESTAURANT_SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case constants.RESTAURANT_SET_DETAILS:
            storage.setRestoDetails(action.payload);
            return {
                ...state,
                restaurant: action.payload.restaurant_details || null,
                branch: action.payload.branch_details || null,
            }
        default:
            return state;
    }
};

