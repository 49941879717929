import constants from "../constants";
import { put, takeEvery, all, call } from 'redux-saga/effects';
import api from '../../webservice/Client';
import Storage from "../../storage/Storage";
import store from '../index';
import paymentAction from '../payments/actions';

function* sendOrderDetails({payload}) {
    try {
        let params = {...payload};
        yield put({ type: constants.ORDER_SET_LOADING, payload: true });
        const customerId = yield call(Storage.getCustomerId);
        if (customerId) {
            params.customer_id = customerId;
        }
        console.log("Order payload", params);
        const order = yield call(api.postOrder, params);

        yield call(Storage.setCustomerId, order.customer_id);
        yield call(Storage.setOrderNumber, order.order_number);
        yield call(Storage.setPrepTime, order.total_preparation_time);
        yield call(Storage.setOrderId, order.id);

        yield call(Storage.clearCart);
        yield put({ type: constants.CART_SET_DETAILS, payload: [] });

        yield put({ type: constants.ORDER_SET_ORDER_DETAILS, payload: order });

        //set paybytill for all orders for now. march 2723-cha
        store.dispatch(paymentAction.onPayByTill({
            "order_id": order.id,
            "status": "pay_in_till"
        }));

        yield put({ type: constants.ORDER_SET_LOADING, payload: false });

        if(typeof payload.onFinished !== 'undefined') {
            payload.onFinished();
        }
    } catch(e) {
        console.log('order error', e);
        yield put({ type: constants.ORDER_SET_LOADING, payload: false });
    }
}

export default function* () {
    yield all([
        takeEvery(constants.ORDER_SEND_ORDER_DETAILS, sendOrderDetails),
    ]);
}
